import slug from 'slug';

export default function convertTextToSlug(text, trim = true) {
  return typeof text === 'string'
    ? slug(text, {
        remove: /[_.]/g,
        lower: true,
        trim,
      })
    : '';
}
